.switch-enter {
    opacity: 0;
    transform: scale(0.5);
    z-index: 1;
}

.switch-enter-active {
    opacity: 1;
    transform: scale(1);
    transition: transform 500ms, opacity 500ms ease;
}

.switch-exit {
    opacity: 1;
    z-index: 0;
}

.switch-exit-active {
    opacity: 0;
    transform: scale(0.5);
    transition: transform 500ms, opacity 500ms ease;
}
